<div class="i4b-device-info" data-cy="i4b-device-info" fxLayout="column">
  <div [ngClass]="{ loading: deviceLoading() }" class="i4b-device-info__card" fxFlex>
    @defer {
      <iot-platform-ui-tab-group
        (selectedTabChange)="onSelectedTabChange($event)"
        [selectedIndex]="initialTabIndex()"
        [withArrowIcon]="true"
        direction="vertical"
        fxFlex
        position="left"
        [shadow]="true"
      >

        <iot-platform-ui-tab-group-meta>
          <div fxLayout="column" fxLayoutAlign="center center">
            @if(toggleMap() && device()?.geolocation?.latitude && device()?.geolocation?.longitude) {
              <iot-platform-maps-geo-coordinates class="device-map"
                                                 concept="device"
                                                 [options]="{ matchScore: device()?.geolocation?.matchScore }"
                                                 [element]="device()"
                                                 [longitude]="device()?.geolocation?.longitude"
                                                 [latitude]="device()?.geolocation?.latitude" />
            } @else {
              <iot-platform-ui-tab-group-image
                (editImageClicked)="editImageClicked()"
                [canUpdateImage]="canDisplayEditActions()"
                defaultImage="assets/images/device/Device_Default.png"
                [image]="device()?.imageUrl"
              />
            }

            @if (!deviceLoading()) {
              <mat-button-toggle-group  [disabled]="!device()?.geolocation?.latitude && !device()?.geolocation?.longitude" class="toggle-map mt-2" [hideSingleSelectionIndicator]="true"
                                       [(ngModel)]="toggleMap">
                <mat-button-toggle [value]="false">
                  <mat-icon>imagesmode</mat-icon>
                </mat-button-toggle>
                <mat-button-toggle [value]="true">
                  <mat-icon>location_on</mat-icon>
                </mat-button-toggle>
              </mat-button-toggle-group>
            }
          </div>

        </iot-platform-ui-tab-group-meta>

        @if (infosTabOptions()?.basicInfosTab?.visible) {
          <iot-platform-ui-tab [disabled]="infosTabOptions()?.basicInfosTab?.disabled">
            <iot-platform-ui-tab-header
                                        [textLabel]="'DEVICES.CARD.TABS.BASIC_INFORMATION' | translate" />
            <iot-platform-ui-tab-content>
              <ng-container [ngTemplateOutlet]="deviceCard" />
            </iot-platform-ui-tab-content>
          </iot-platform-ui-tab>
        }

        @if (infosTabOptions()?.advancedSettingsTab?.visible) {
          <iot-platform-ui-tab [disabled]="infosTabOptions()?.advancedSettingsTab?.disabled">
            <iot-platform-ui-tab-header
                                        [textLabel]="'DEVICES.CARD.TABS.ADVANCED_SETTINGS' | translate" />
            <iot-platform-ui-tab-content>
              <ng-container [ngTemplateOutlet]="deviceCard" />
            </iot-platform-ui-tab-content>
          </iot-platform-ui-tab>
        }
      </iot-platform-ui-tab-group>
    }
  </div>
</div>

<ng-template #deviceCard>
  <iot-platform-ui-overview-card
    (cardButtonClicked)="onCardActions($event)"
    [loading]="deviceLoading()"
    [overviewCard]="overviewCard()"
    class="i4b-device-info__overview-card"
    fxFlex
  >
    <ng-container cardNameMeta>
      @if (device(); as device) {
        @if (device?.status) {
          <iot-platform-ui-event-severity-display
            data-cy="i4b-device-info-device-status"
            class="i4b-device-info__overview-card__status"
            [severity]="device.status?.name"
            [tooltip]="statusTooltip()"
          />
        }
      }
    </ng-container>
    <ng-container cardHeader>
      @if (device(); as device) {
        <div fxFlex fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="start">
          <iot-platform-ui-device-info-status [device]="device" />
          <iot-platform-ui-device-latest-command-status [device]="device" />
        </div>
      }
    </ng-container>
  </iot-platform-ui-overview-card>
</ng-template>
