export enum FormControlType {
  TEXT = 'text',
  TEXT_AREA = 'TEXT_AREA',
  NUMBER = 'number',
  DROP_DOWN_LIST = 'DROP_DOWN_LIST',
  AUTO_COMPLETE = 'AUTO_COMPLETE',
  PASSWORD = 'password',
  CHECKBOX_GROUP = 'CHECKBOX_GROUP',
  DATE_PICKER = 'DATE_PICKER'
}
