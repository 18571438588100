import { FormControl, FormGroup } from '@angular/forms';
import { Product } from '@iot-platform/models/common';
import { AssetStatusName } from '@iot-platform/models/i4b';

export enum AssetInfoFormControlName {
  NAME = 'name',
  STATUS = 'status',
  DESCRIPTION = 'description',
  BUSINESS_ID = 'businessId',
  DELIVERY_DATE = 'deliveryDate',
  PRODUCT_1 = 'product1',
  PRODUCT_2 = 'product2',
  QUANTITY_1 = 'quantity1',
  QUANTITY_2 = 'quantity2',
  SHIP_TO = 'shipTo',
  OPTIONAL_PROPERTIES = 'optionalProperties'
}

export type AssetInfoFormGroup = FormGroup<
  Partial<{
    [AssetInfoFormControlName.NAME]: FormControl<string>;
    [AssetInfoFormControlName.STATUS]: FormControl<AssetStatusName>;
    [AssetInfoFormControlName.DESCRIPTION]: FormControl<string>;
    [AssetInfoFormControlName.BUSINESS_ID]: FormControl<string>;
    [AssetInfoFormControlName.DELIVERY_DATE]: FormControl<string>;
    [AssetInfoFormControlName.PRODUCT_1]: FormControl<Product>;
    [AssetInfoFormControlName.PRODUCT_2]: FormControl<Product>;
    [AssetInfoFormControlName.QUANTITY_1]: FormControl<string>;
    [AssetInfoFormControlName.QUANTITY_2]: FormControl<string>;
    [AssetInfoFormControlName.SHIP_TO]: FormControl<string>;
    [AssetInfoFormControlName.OPTIONAL_PROPERTIES]: FormControl<string[]>;
  }>
>;
