import { NgClass } from '@angular/common';
import { Component, forwardRef, Input, input } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AbstractControl, ControlValueAccessor, FormArray, FormControl, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TranslateModule } from '@ngx-translate/core';
import { isEqual } from 'lodash';

@Component({
  selector: 'iot-platform-ui-checkbox-group',
  imports: [TranslateModule, FlexLayoutModule, ReactiveFormsModule, FormsModule, MatCheckboxModule, MatFormFieldModule, NgClass],
  templateUrl: './checkbox-group.component.html',
  styleUrls: [],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxGroupComponent),
      multi: true
    }
  ]
})
export class CheckboxGroupComponent implements ControlValueAccessor {
  private onTouched!: (_?: any) => void;
  private onChange!: (_?: any) => void;

  items = input.required<any[]>();
  fxFlexOption = input<string>();
  capitalized = input<boolean>();
  disabled = input<boolean>(false);
  @Input() isOptionDisabled!: (item: any) => boolean;
  @Input() selectBy!: (item: any) => any;
  @Input() displayBy!: (item: any) => any;
  @Input() selectionChangeInput: (item: any) => any;
  optionsControl: FormArray = new FormArray<FormControl<string>>([]);

  selectByFn(item: any) {
    return this.selectBy ? this.selectBy(item) : item;
  }

  displayByFn(item: any) {
    return this.displayBy ? this.displayBy(item) : item;
  }

  writeValue(values: any[]): void {
    if (values) {
      const items = this.items().filter((item) => !!values.find((e1) => isEqual(e1, this.selectByFn(item))));
      items.forEach((item) => {
        this.onSelectionChange({
          checked: true,
          source: { value: this.selectByFn(item) }
        } as MatCheckboxChange);
      });
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  isChecked(item: any): boolean {
    return this.optionsControl.getRawValue().includes(this.selectByFn(item));
  }

  onSelectionChange(event: MatCheckboxChange): void {
    if (this.onTouched) {
      this.onTouched();
    }
    if (event.checked === true) {
      this.optionsControl.push(new FormControl<string>(event.source.value));
    } else {
      this.optionsControl.controls.forEach((control: AbstractControl, index: number) => {
        if (control.getRawValue() === event.source.value) {
          this.optionsControl.removeAt(index);
        }
      });
    }
    if (this.selectionChangeInput) {
      console.log('aaaah');
      this.selectionChangeInput(this.optionsControl.getRawValue());
    } else if (this.onChange) {
      console.log('???', this.onChange);
      this.onChange(this.optionsControl.getRawValue());
    } else {
      console.log('!!!');
    }
  }
}
