<mat-card class="dialog-card">
  <mat-card-title class="dialog-card-header">
    <mat-toolbar class="dialog-toolbar" color="accent" fxLayout="row" fxLayoutAlign="space-between center">
      <h1 class="dialog-card-title">{{ 'ASSETS.SHELL.CREATE_ASSET_FROM_SCRATCH' | translate }}</h1>
      <button (click)="close()" class="regular-round-button" mat-icon-button>
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar>
  </mat-card-title>

  <mat-card-content class="dialog-card-content create-asset-from-scratch-dialog__content">
    <mat-stepper #stepper [animationDuration]="'500'" [linear]="true" color="accent" headerPosition="top" labelPosition="end">
      @for (step of steps(); track step) {
        <mat-step
          [label]="'IOT_DICTIONARY.' + step | translate"
          (interacted)="onStepperInteracted($event)"
          [stepControl]="form?.get(step)"
          [completed]="form?.get(step)?.valid"
        >
          <ng-template matStepContent>
            @switch (step) {
              @case (AssetCreationStep.MODEL) {
                <iot4bos-ui-asset-model-form (fillOutAssetModel)="onChangedForm(step, $event)" [site]="data.site" [withBlankModel]="true" />
              }
              @case (AssetCreationStep.INFORMATION) {
                <iot4bos-ui-asset-info-form
                  (fillOutAsset)="onChangedForm(step, $event)"
                  [selectedFields]="selectedFields()"
                  [optprops]="optionalPropertiesFromModel()"
                  [siteId]="site?.id"
                  [canUpdateAsset]="true"
                />
              }
              @case (AssetCreationStep.TAGS) {
                <iot-platform-ui-manage-tags-form [data]="manageTagFormData()" (updateForm)="onChangedForm(step, $event)" />
              }
              @case (AssetCreationStep.IMAGE) {
                <iot4bos-ui-asset-image-form
                  style="display: block; height: 400px"
                  [asset]="{ imageUrl: imageControl?.getRawValue() }"
                  [tagCategories]="tagsControl.getRawValue()"
                  (updateForm)="onChangedForm(step, $event)"
                />
              }
            }
          </ng-template>
        </mat-step>
      }
    </mat-stepper>
  </mat-card-content>

  <mat-card-actions class="dialog-card-actions" fxLayout="row" fxLayoutAlign="end center">
    <button (click)="close()" class="button-regular" mat-button>{{ 'IOT_DICTIONARY.CANCEL' | translate }}</button>
    @if (stepper.selectedIndex !== 0) {
      <button (click)="stepper.previous()" class="button-regular" color="accent" mat-button>
        {{ 'IOT_DICTIONARY.BACK' | translate }}
      </button>
    }
    @if (stepper.selectedIndex !== steps().length - 1) {
      <button (click)="stepper.next()" [disabled]="!stepper.selected?.completed" class="button-regular" color="accent" mat-button>
        {{ 'IOT_DICTIONARY.NEXT' | translate }}
      </button>
    } @else {
      <button (click)="save()" [disabled]="!form.valid" class="button-regular" color="accent" mat-raised-button>
        {{ 'IOT_DICTIONARY.CREATE' | translate }}
      </button>
    }
  </mat-card-actions>
</mat-card>
