import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { TagCategory } from '@iot-platform/models/common';
import { AssetStatusName, QUANTITIES } from '@iot-platform/models/i4b';
import { map, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AssetsDirectoryService {
  private readonly httpClient: HttpClient = inject(HttpClient);

  getImages(): Observable<string[]> {
    return this.httpClient.get<string[]>('assets/data/assets-gallery.json');
  }

  getFilteredImagesByTag(tagCategories: TagCategory[]): Observable<string[]> {
    return this.getImages().pipe(
      map((imagePaths: string[]) => {
        const technoTag: string = tagCategories.find((cat) => cat.name?.toUpperCase() === 'TECHNO')?.labels?.[0]?.name.toLowerCase() || '';
        const prefixes = imagePaths.map((imagePath: string) => {
          const name = imagePath.split('/').pop()?.toLowerCase() ?? '';
          return name.split('_')[0];
        });

        if (prefixes.includes(technoTag)) {
          return imagePaths.filter((imagePath: string) => {
            const imageName = imagePath.split('/').pop() as string;
            if (imageName.split('_')[0]?.toLowerCase() === technoTag || imageName === 'asset_default.png') {
              return true;
            } else {
              return false;
            }
          });
        } else {
          return imagePaths;
        }
      })
    );
  }

  getDefaultImage(): string {
    return 'assets/images/asset/asset_default.png';
  }

  getAvailableStatusesForEdition(): Observable<AssetStatusName[]> {
    return of([AssetStatusName.production, AssetStatusName.maintenance]);
  }

  getQuantities(): Observable<(string | null)[]> {
    return of(QUANTITIES);
  }
}
