import { BaseAsset } from '../common';
import { ErpReference } from '../common/';
import { DataGuardCheckStatus } from '../data-guard';
import { AssetGroup } from './asset-group.model';
import { AssetStatus } from './asset-status.model';
import { AssetVariable } from './asset-variable.model';
import { CommandType } from './enums';
import { FollowedVariable } from './followed-variable';
import { Site } from './site.model';

export interface FollowedVariables {
  1: FollowedVariable | null;
  2: FollowedVariable | null;
  3: FollowedVariable | null;
}

export enum AssetOptionalProperty {
  businessId = 'businessId',
  deliveryDate = 'deliveryDate',
  product1 = 'product1',
  product2 = 'product2',
  quantity1 = 'quantity1',
  quantity2 = 'quantity2',
  shipTo = 'shipTo'
}

export interface Asset extends BaseAsset {
  assetTemplate?: { id: string };
  assetGroup?: AssetGroup;
  checkStatus?: DataGuardCheckStatus;
  commands?: CommandType[] | null;
  erpReference: ErpReference;
  expandedVariables: { [assetVariableName: string]: Partial<AssetVariable> };
  followedVariables?: FollowedVariables;
  isRefreshCommandEnabled?: boolean;
  optionalProperties: { [propertyName in AssetOptionalProperty]: boolean };
  serviceId?: string;
  site?: Site;
  status?: AssetStatus;
  tags?: string[];
  priority?: number;
}
