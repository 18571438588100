<div fxLayout="row" fxLayoutGap="20px">
  @if (selectedFields().includes(AssetInfoFormControlName.OPTIONAL_PROPERTIES) && canUpdateAsset()) {
    <div fxFlex="20%" fxLayout="column" fxLayout.xs="column" fxLayoutAlign.xs="start" fxLayoutGap="10px">
      <span class="fs-12 fw-600">{{ 'ASSETS.INFO_FORM.SELECT_ASSET_PROPERTIES' | translate }}</span>

      <!--      <iot-platform-ui-form-field fxLayout="column" [control]="form.get(AssetInfoFormControlName.OPTIONAL_PROPERTIES)" [field]="optPropField()[0]" />-->
      @for (p of OPTIONAL_PROPERTIES; track p.value) {
        <mat-checkbox
          (change)="form.markAsDirty(); onOptPropChange($event)"
          [checked]="getFormControl(AssetInfoFormControlName.OPTIONAL_PROPERTIES)?.getRawValue()?.includes(p.value)"
          [value]="p.value"
          >{{ p.label | translate }}
        </mat-checkbox>
      }
      @if (getOptionalProperties()?.[AssetInfoFormControlName.PRODUCT_1] || getOptionalProperties()?.[AssetInfoFormControlName.PRODUCT_2]) {
        <p class="dialog-form-tips">{{ 'ASSETS.INFO_FORM.PRODUCT_CONFIGURATION' | translate }}</p>
      }
    </div>
    <hr class="asset-info-form__separator" />
  }
  <div fxLayout="column" fxFlex>
    <iot-platform-ui-form-group [fields]="fields()" [group]="form" />
    <p class="dialog-form-tips">{{ 'IOT_DICTIONARY.MANDATORY_FIELDS' | translate }}</p>
  </div>
</div>

<!--<div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-around start" fxLayoutAlign.xs="start center">-->
<!--  <form class="p-20 asset-info-form__form" [formGroup]="form" fxLayout="column" fxFlex style="width: 100%">-->
<!--    <mat-form-field fxFlex color="accent">-->
<!--      <mat-label>{{ 'ASSETS.INFO_FORM.ASSET_NAME' | translate }}</mat-label>-->
<!--      <input formControlName="name" matInput #nameInput required maxlength="50" pattern="\S.*" />-->
<!--      @if (name.invalid && (name.errors?.maxlength || name.errors?.pattern)) {-->
<!--        <mat-error>{{ 'ASSETS.INFO_FORM.ERROR_MESSAGE.NAME' | translate }}</mat-error>-->
<!--      }-->
<!--      @if (name.invalid && name.errors?.nameExists) {-->
<!--        <mat-error>{{ 'ASSETS.INFO_FORM.ERROR_MESSAGE.DUPLICATE' | translate }}</mat-error>-->
<!--      }-->
<!--      <mat-hint align="end">{{ nameInput.value?.length || 0 }}/50</mat-hint>-->
<!--    </mat-form-field>-->

<!--    @if (asset()?.optionalProperties?.businessId) {-->
<!--      <mat-form-field class="asset-info-form__form_businessId" fxFlex color="accent">-->
<!--        <mat-label>{{ 'ASSETS.INFO_FORM.ID' | translate }}</mat-label>-->
<!--        <input #businessIdInput formControlName="businessId" matInput maxlength="30" />-->
<!--        <mat-hint align="end">{{ businessIdInput.value?.length || 0 }}/30</mat-hint>-->
<!--      </mat-form-field>-->
<!--    }-->

<!--    @if (!asset()?.id) {-->
<!--      <mat-form-field fxFlex color="accent" class="asset-info-form__form_status">-->
<!--        <mat-label>{{ 'ASSETS.INFO_FORM.STATUS' | translate }}</mat-label>-->
<!--        <mat-select formControlName="status" required>-->
<!--          @for (status of allStatus(); track status) {-->
<!--            <mat-option [value]="status">{{ 'ASSETS.CARD.STATUS_LIST.' + status | translate }}</mat-option>-->
<!--          }-->
<!--        </mat-select>-->
<!--        @if (status.invalid) {-->
<!--          <mat-error>{{ 'ASSETS.INFO_FORM.ERROR_MESSAGE.STATUS' | translate }}</mat-error>-->
<!--        }-->
<!--      </mat-form-field>-->
<!--    }-->

<!--    @if (asset()?.optionalProperties?.shipTo) {-->
<!--      <mat-form-field class="asset-info-form__form_shipTo" fxFlex color="accent">-->
<!--        <mat-label>{{ 'ASSETS.INFO_FORM.SHIP_TO' | translate }}</mat-label>-->
<!--        <input #shipToInput formControlName="shipTo" matInput maxlength="50" />-->
<!--        <mat-hint align="end">{{ shipToInput.value?.length || 0 }}/50</mat-hint>-->
<!--      </mat-form-field>-->
<!--    }-->
<!--    @if (asset()?.optionalProperties?.deliveryDate) {-->
<!--      <mat-form-field fxFlex class="asset-info-form__form_deliveryDate" color="accent">-->
<!--        <mat-label>{{ 'ASSETS.INFO_FORM.DELIVERY_DATE' | translate }}</mat-label>-->
<!--        <input matInput formControlName="deliveryDate" [matDatepicker]="deliveryDatePicker" (focus)="deliveryDatePicker.open()" />-->
<!--        <mat-datepicker-toggle [disableRipple]="true" matSuffix [for]="deliveryDatePicker">-->
<!--          <mat-icon matDatepickerToggleIcon>arrow_drop_down</mat-icon>-->
<!--        </mat-datepicker-toggle>-->
<!--        <mat-datepicker #deliveryDatePicker></mat-datepicker>-->
<!--      </mat-form-field>-->
<!--    }-->

<!--    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start center" fxLayoutGap="20px">-->
<!--      @if (asset()?.optionalProperties?.quantity1) {-->
<!--        <mat-form-field fxFlex class="asset-info-form__form_quantity1" style="width: 100%" color="accent">-->
<!--          <mat-label>{{ 'ASSETS.TEMPLATE_FORM.QUANTITY_1' | translate }}</mat-label>-->
<!--          <mat-select formControlName="quantity1">-->
<!--            @for (quantity of quantities(); track quantity) {-->
<!--              <mat-option [value]="quantity">{{ quantity }}</mat-option>-->
<!--            }-->
<!--          </mat-select>-->
<!--        </mat-form-field>-->
<!--      }-->
<!--      @if (asset()?.optionalProperties?.quantity2) {-->
<!--        <mat-form-field fxFlex class="asset-info-form__form_quantity2" style="width: 100%" color="accent">-->
<!--          <mat-label>{{ 'ASSETS.TEMPLATE_FORM.QUANTITY_2' | translate }}</mat-label>-->
<!--          <mat-select formControlName="quantity2">-->
<!--            @for (quantity of quantities(); track quantity) {-->
<!--              <mat-option [value]="quantity">{{ quantity }}</mat-option>-->
<!--            }-->
<!--          </mat-select>-->
<!--        </mat-form-field>-->
<!--      }-->
<!--    </div>-->

<!--    <mat-form-field fxFlex color="accent">-->
<!--      <mat-label>{{ 'ASSETS.INFO_FORM.DESCRIPTION' | translate }}</mat-label>-->
<!--      <textarea #descriptionInput formControlName="description" maxlength="300" matInput rows="1"></textarea>-->
<!--      <mat-hint align="end">{{ descriptionInput.value?.length || 0 }}/300</mat-hint>-->
<!--    </mat-form-field>-->

<!--    <p class="dialog-form-tips" style="padding-bottom: 1rem">{{ 'ASSETS.INFO_FORM.MANDATORY_FIELDS' | translate }}</p>-->

<!--    &lt;!&ndash; Display optional properties fields only when creation &ndash;&gt;-->
<!--    @if (!asset()) {-->
<!--      <div style="padding-bottom: 1rem" fxFlex>-->
<!--        {{ 'ASSETS.INFO_FORM.SELECT_ASSET_PROPERTIES' | translate }}-->
<!--      </div>-->
<!--      <div-->
<!--        class="asset-info-form__optional-properties"-->
<!--        [ngStyle.sm]="{ 'padding-left': '0px' }"-->
<!--        style="padding-left: 10%"-->
<!--        fxLayout="row wrap"-->
<!--        fxLayout.sm="column"-->
<!--        fxLayoutAlign="start center"-->
<!--        fxLayoutAlign.sm="start"-->
<!--      >-->
<!--        @for (item of optionalProperties(); track item) {-->
<!--          <div fxFlex="33%" fxFlex.sm="100%" fxLayoutGap="20px">-->
<!--            <mat-checkbox fxFlex [value]="item.key" (change)="onSelectOptionalPropertiesChange($event)" [checked]="item.value">{{-->
<!--              'ASSETS.MANAGE_PROPERTIES_FORM.' + item.key | translate-->
<!--            }}</mat-checkbox>-->
<!--          </div>-->
<!--        }-->
<!--      </div>-->
<!--    }-->
<!--  </form>-->
<!--</div>-->
