import { FormControl, FormGroup } from '@angular/forms';
import { Connector } from '@iot-platform/models/common';
import { DeviceStatusName } from '@iot-platform/models/i4b';

export enum DeviceInfoFormControlNames {
  NAME = 'name',
  STATUS = 'status',
  ENDPOINT = 'endpoint',
  OUTGOING_CONNECTOR = 'outgoingConnector',
  LOGIN = 'login',
  PASSWORD = 'password',
  DESCRIPTION = 'description'
}

export type DeviceInfoFormGroup = FormGroup<
  Partial<{
    [DeviceInfoFormControlNames.NAME]: FormControl<string>;
    [DeviceInfoFormControlNames.STATUS]: FormControl<DeviceStatusName>;
    [DeviceInfoFormControlNames.ENDPOINT]: FormControl<string>;
    [DeviceInfoFormControlNames.OUTGOING_CONNECTOR]: FormControl<Connector>;
    [DeviceInfoFormControlNames.LOGIN]: FormControl<string>;
    [DeviceInfoFormControlNames.PASSWORD]: FormControl<string>;
    [DeviceInfoFormControlNames.DESCRIPTION]: FormControl<string>;
  }>
>;
