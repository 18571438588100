import { NgClass, NgTemplateOutlet } from '@angular/common';
import { Component, computed, inject, input, model, output, signal, Signal } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { MatButtonToggle, MatButtonToggleGroup } from '@angular/material/button-toggle';
import { MatDialog } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { AnalyticsService } from '@iot-platform/core';
import { MapGeoCoordinatesComponent } from '@iot-platform/iot-platform-maps';
import {
  ChipComponent,
  DeviceInfoStatusModule,
  DeviceLatestCommandStatusComponent,
  EventSeverityDisplayModule,
  OverviewCardComponent,
  TabsModule
} from '@iot-platform/iot-platform-ui';
import { DevicesFacade } from '@iot-platform/iot4bos/data-access/devices';
import { OverviewCard, OverviewCardActions, TagCategory } from '@iot-platform/models/common';
import { CommandType, Device, DeviceStatusName, Site } from '@iot-platform/models/i4b';
import { DateFormatPipe } from '@iot-platform/pipes';
import { DeviceEndpointComponent, DeviceIccidComponent } from '@iot-platform/ui/devices';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { get } from 'lodash';
import { NavigationApi } from '../../../../containers/+state/navigation.api';
import { DevicesCtaService } from '../../services/devices-cta.service';

@Component({
  selector: 'iot4bos-ui-device-info',
  imports: [
    OverviewCardComponent,
    DeviceLatestCommandStatusComponent,
    DeviceInfoStatusModule,
    EventSeverityDisplayModule,
    FlexLayoutModule,
    TabsModule,
    TranslatePipe,
    NgClass,
    NgTemplateOutlet,
    MapGeoCoordinatesComponent,
    MatButtonToggle,
    MatButtonToggleGroup,
    MatIcon,
    FormsModule
  ],
  templateUrl: './device-info.component.html',
  styleUrls: ['./device-info.component.scss']
})
export class DeviceInfoComponent {
  private readonly devicesFacade: DevicesFacade = inject(DevicesFacade);
  private readonly navigationApi: NavigationApi = inject(NavigationApi);
  private readonly translateService: TranslateService = inject(TranslateService);
  private readonly devicesCtaService: DevicesCtaService = inject(DevicesCtaService);
  private readonly dateFormatPipe: DateFormatPipe = inject(DateFormatPipe);
  public readonly dialog: MatDialog = inject(MatDialog);
  analytic: AnalyticsService = new AnalyticsService('device_info_page');

  toggleMap = model<boolean>(false);
  site = input<Site>();
  canRead = input<boolean>(false);
  canCreate = input<boolean>(false);
  canUpdate = input<boolean>(false);
  canDelete = input<boolean>(false);
  canReadAuditTrail = input<boolean>(false);
  configureDeviceConnector = output<Device>();

  device: Signal<Device> = this.navigationApi.selectedDevice;
  deviceLoading: Signal<boolean> = this.devicesFacade.loading;
  deviceTags: Signal<TagCategory[]> = this.navigationApi.deviceTags;
  deviceTagsLoaded: Signal<boolean> = this.navigationApi.deviceTagsLoaded;
  canDisplayEditActions = computed(() => {
    const canUpdate = this.canUpdate();
    const device = this.device();
    return canUpdate && device?.status?.name !== DeviceStatusName.decommissioned && device && device?.status?.name !== DeviceStatusName.test_mode;
  });
  width = '50%';
  initialTabIndex = signal<number>(0);
  infosTabOptions = signal({
    basicInfosTab: {
      visible: true,
      disabled: false
    },
    advancedSettingsTab: {
      visible: true,
      disabled: false
    }
  });

  overviewCard: Signal<OverviewCard> = computed(() => {
    const device = this.device();
    const tags = this.deviceTags();
    return this.getCardMetadata(device, tags);
  });

  statusTooltip: Signal<string | null> = computed(() => {
    const device = this.device();
    const updatedAt = device?.status?.datetime ? this.dateFormatPipe.transform(device?.status?.datetime) : null;
    return updatedAt ? this.translateService.instant('IOT_DICTIONARY.SINCE') + ' : ' + updatedAt : null;
  });
  isCommandDisabled: Signal<boolean> = computed(() => {
    const device = this.device();
    return device?.outgoingConnector?.requestConfiguration?.authentication === 'login' && (!device.credential?.login || !device.credential?.password);
  });

  get isBasicInfoTabSelected() {
    return this.initialTabIndex() === 0;
  }

  get isAdvancedSettingsTabSelected() {
    return this.initialTabIndex() === 1;
  }

  onSelectedTabChange({ index }) {
    this.initialTabIndex.set(index);
  }

  editImageClicked() {
    this.analytic.log('tab_device_info_actions', 'open_edit_image');
    this.devicesCtaService.editDeviceImage(this.device());
  }

  onCardActions(actionType: OverviewCardActions): void {
    switch (actionType) {
      case OverviewCardActions.ACTIVATE:
        this.analytic.log('tab_device_info_actions', 'open_activate_device');
        this.devicesCtaService.activateDevice(this.device());
        break;
      case OverviewCardActions.MOVE_TO:
        this.analytic.log('tab_device_info_actions', 'open_move_device');
        this.devicesCtaService.moveDevice(this.device(), this.site());
        break;
      case OverviewCardActions.RESET:
        this.analytic.log('tab_device_info_actions', 'open_reset_device');
        this.devicesCtaService.resetDevice(this.device());
        break;
      case OverviewCardActions.DECOMMISSION:
        this.analytic.log('tab_device_info_actions', 'open_decommission_device');
        this.devicesCtaService.decommissionDevice(this.device());
        break;
      case OverviewCardActions.DELETE:
        this.analytic.log('tab_device_info_actions', 'open_delete_device');
        this.devicesCtaService.deleteDevice(this.device());
        break;
      case OverviewCardActions.CONFIGURE:
        this.onConfigureDevice();
        break;
      case OverviewCardActions.START_MAINTENANCE:
      case OverviewCardActions.EDIT_MAINTENANCE:
        this.analytic.log('tab_device_info_actions', 'open_schedule_maintenance');
        this.devicesCtaService.startOrEditMaintenance(this.device());
        break;
      case OverviewCardActions.END_MAINTENANCE:
        this.analytic.log('tab_device_info_actions', 'open_end_maintenance');
        this.devicesCtaService.endMaintenance(this.device());
        break;
      case OverviewCardActions.SELFCONF_COMMAND:
        this.onDeviceCommand(CommandType.SELFCONF);
        break;
      case OverviewCardActions.REFRESH_COMMAND:
        this.onDeviceCommand(CommandType.REFRESH);
        break;
      case OverviewCardActions.UNBLOCK_COMMAND:
        this.onDeviceCommand(CommandType.UNBLOCK);
        break;
      case OverviewCardActions.GET_ALARM_COMMAND:
        this.onDeviceCommand(CommandType.GET_ALARM);
        break;
      case OverviewCardActions.DISPLAY_HISTORY:
        this.analytic.log('tab_device_info_actions', 'open_history');
        this.devicesCtaService.openAuditTrail(this.device());
        break;
      default:
        break;
    }
  }

  onConfigureDevice(): void {
    this.configureDeviceConnector.emit(this.device());
  }

  onDeviceCommand(command: CommandType): void {
    this.analytic.log('tab_device_info_actions', 'send_command', `${command}`);
    this.devicesFacade.sendCommand(this.device(), { command });
  }

  private getCardMetadata(device: Device, tags: TagCategory[]): OverviewCard {
    const canDisplayEditActions = this.canDisplayEditActions();
    const canCreateDevice = this.canCreate();
    const canUpdateDevice = this.canUpdate();
    const canDeleteDevice = this.canDelete();
    const canReadAuditTrail = this.canReadAuditTrail();
    const deviceStatus: DeviceStatusName | '' = get(device, 'status.name', '');
    const deviceTagsLoaded = this.deviceTagsLoaded();
    const width = this.width;

    return {
      name: device?.name as string,
      justifyContent: true,
      nameMetaAction: {
        visible: canDisplayEditActions,
        type: 'edit-device-name' as OverviewCardActions,
        icon: 'edit',
        tooltip: 'IOT_DICTIONARY.EDIT',
        dispatchEvent: () => this.devicesCtaService.editDeviceNameAndStatus(device)
      },
      actions: [
        {
          label: 'DEVICES.CARD.ACTIVATE',
          type: OverviewCardActions.ACTIVATE,
          visible: canCreateDevice && deviceStatus === DeviceStatusName.test_mode && !!device?.affiliate
        },
        {
          label: 'DEVICES.CARD.MOVE_TO',
          type: OverviewCardActions.MOVE_TO,
          visible: canUpdateDevice && deviceStatus !== DeviceStatusName.test_mode
        },
        {
          label: 'DEVICES.CARD.RESET',
          type: OverviewCardActions.RESET,
          visible: canDisplayEditActions
        },
        {
          label: 'DEVICES.CARD.DECOMMISSION',
          type: OverviewCardActions.DECOMMISSION,
          visible: canDisplayEditActions
        },
        {
          label: 'IOT_DICTIONARY.DELETE',
          type: OverviewCardActions.DELETE,
          disabled: deviceStatus !== DeviceStatusName.decommissioned,
          visible: canDeleteDevice
        },
        {
          label: 'DEVICES.CARD.CONFIGURE',
          type: OverviewCardActions.CONFIGURE,
          visible: canCreateDevice && !!device?.incomingConnector?.configuration?.url && deviceStatus !== DeviceStatusName.decommissioned,
          divider: true
        },
        {
          label: 'DEVICES.CARD.START_MAINTENANCE',
          type: OverviewCardActions.START_MAINTENANCE,
          visible: canUpdateDevice && (deviceStatus === DeviceStatusName.standby || deviceStatus === DeviceStatusName.running),
          divider: true
        },
        {
          label: 'DEVICES.CARD.EDIT_MAINTENANCE',
          type: OverviewCardActions.EDIT_MAINTENANCE,
          visible: canUpdateDevice && deviceStatus === DeviceStatusName.maintenance
        },
        {
          label: 'DEVICES.CARD.END_MAINTENANCE',
          type: OverviewCardActions.END_MAINTENANCE,
          visible: canUpdateDevice && deviceStatus === DeviceStatusName.maintenance
        },
        {
          label: 'DEVICES.CARD.SELFCONF',
          type: OverviewCardActions.SELFCONF_COMMAND,
          disabled: this.isCommandDisabled(),
          visible: this.hasCommand(CommandType.SELFCONF) && deviceStatus !== DeviceStatusName.decommissioned && deviceStatus !== DeviceStatusName.test_mode,
          divider: true,
          valueMeta: {
            icon: 'info_outlined',
            tooltip: 'DEVICES.CARD.ACTION_INFORMATION_TOOLTIP',
            visibleExpression: () => this.isCommandDisabled()
          }
        },
        {
          label: 'DEVICES.CARD.REFRESH',
          type: OverviewCardActions.REFRESH_COMMAND,
          disabled: this.isCommandDisabled(),
          visible: this.hasCommand(CommandType.REFRESH) && deviceStatus !== DeviceStatusName.decommissioned && deviceStatus !== DeviceStatusName.test_mode,
          valueMeta: {
            icon: 'info_outlined',
            tooltip: 'DEVICES.CARD.ACTION_INFORMATION_TOOLTIP',
            visibleExpression: () => this.isCommandDisabled()
          }
        },
        {
          label: 'DEVICES.CARD.UNBLOCK',
          type: OverviewCardActions.UNBLOCK_COMMAND,
          disabled: this.isCommandDisabled(),
          visible: this.hasCommand(CommandType.UNBLOCK) && deviceStatus !== DeviceStatusName.decommissioned && deviceStatus !== DeviceStatusName.test_mode,
          valueMeta: {
            icon: 'info_outlined',
            tooltip: 'DEVICES.CARD.ACTION_INFORMATION_TOOLTIP',
            visibleExpression: () => this.isCommandDisabled()
          }
        },
        {
          label: 'DEVICES.CARD.GET_ALARM',
          type: OverviewCardActions.GET_ALARM_COMMAND,
          disabled: this.isCommandDisabled(),
          visible: this.hasCommand(CommandType.GET_ALARM) && deviceStatus !== DeviceStatusName.decommissioned && deviceStatus !== DeviceStatusName.test_mode,
          valueMeta: {
            icon: 'info_outlined',
            tooltip: 'DEVICES.CARD.ACTION_INFORMATION_TOOLTIP',
            visibleExpression: () => this.isCommandDisabled()
          }
        },
        {
          label: 'AUDIT_TRAIL.CTA.HISTORY',
          type: OverviewCardActions.DISPLAY_HISTORY,
          visible: canReadAuditTrail,
          divider: true
        }
      ],
      sections: [
        //  GENERAL SECTION
        {
          title: 'IOT_DICTIONARY.GENERAL',
          collapsible: true,
          expanded: true,
          visibleExpression: () => this.isBasicInfoTabSelected,
          action: {
            type: 'edit-general-info' as OverviewCardActions,
            icon: 'edit',
            tooltip: 'IOT_DICTIONARY.EDIT',
            visible: canDisplayEditActions,
            disabled: this.infosTabOptions().basicInfosTab.disabled,
            dispatchEvent: () => this.devicesCtaService.editDeviceGeneralInfo(device)
          },
          properties: [
            { label: 'DEVICES.CARD.IDENTIFIER', value: device?.identifier, width },
            { label: 'DEVICES.CARD.FAMILY', value: device?.type?.family, width },
            { label: 'DEVICES.CARD.SUPPORT', value: device?.communication?.support, width },
            { label: 'DEVICES.CARD.MODEL', value: device?.type?.model, width },
            {
              label: 'DEVICES.CARD.ENDPOINT',
              value: device?.communication?.endpoint,
              width,
              componentRef: {
                ref: DeviceEndpointComponent,
                inputs: {
                  support: device?.communication?.support,
                  endpoint: device?.communication?.endpoint,
                  httpPort: device?.communication?.httpport
                }
              }
            },
            { label: 'DEVICES.CARD.FIRMWARE', value: device?.type?.firmware, width },
            { label: 'IOT_DICTIONARY.DESCRIPTION', innerHTMLValue: device?.description, width: '100%', cssClass: 'flex-col' }
          ]
        },
        {
          title: 'DEVICES.CARD.ORGANIZATION',
          collapsible: true,
          expanded: true,
          visibleExpression: () => this.isBasicInfoTabSelected,
          properties: [
            { label: 'DEVICES.CARD.AFFILIATE_ID', value: device?.affiliate, width },
            { label: 'DEVICES.CARD.AFFILIATE_NAME', value: device?.affiliateName, width }
          ]
        },
        {
          title: 'DEVICES.CARD.CONNECTOR',
          collapsible: true,
          expanded: true,
          visibleExpression: () => this.isAdvancedSettingsTabSelected,
          action: {
            visible: canDisplayEditActions,
            type: 'edit-device-connector-info' as OverviewCardActions,
            icon: 'edit',
            tooltip: 'IOT_DICTIONARY.EDIT',
            disabled: this.infosTabOptions().advancedSettingsTab.disabled,
            dispatchEvent: () => this.devicesCtaService.editDeviceConnectorInfo(device)
          },
          properties: [
            { label: 'DEVICES.CARD.INCOMING_CONNECTOR_ID', value: device?.incomingConnector?.id, width },
            { label: 'DEVICES.CARD.OUTGOING_CONNECTOR_ID', value: device?.outgoingConnector?.id, width },
            { label: 'DEVICES.CARD.INCOMING_CONNECTOR_NAME', value: device?.incomingConnector?.name, width },
            { label: 'DEVICES.CARD.OUTGOING_CONNECTOR_NAME', value: device?.outgoingConnector?.name, width }
          ]
        },
        {
          title: 'DEVICES.CARD.TELECOM',
          collapsible: true,
          expanded: true,
          visibleExpression: () => this.isBasicInfoTabSelected,
          properties: [
            {
              label: 'DEVICES.CARD.ICCID',
              value: device?.communication?.iccid,
              width,
              componentRef: {
                ref: DeviceIccidComponent,
                inputs: {
                  operator: device?.communication?.operator,
                  iccid: device?.communication?.iccid,
                  deviceId: device?.identifier
                }
              }
            },
            { label: 'DEVICES.CARD.OPERATOR', value: device?.communication?.operator, width }
          ]
        },
        //  TAGS SECTION
        {
          title: 'IOT_DICTIONARY.TAGS',
          collapsible: true,
          expanded: true,
          style: { 'margin-bottom': '10px' },
          visibleExpression: () => this.isBasicInfoTabSelected,
          action: {
            type: OverviewCardActions.MANAGE_TAGS,
            icon: 'edit',
            tooltip: 'IOT_DICTIONARY.EDIT',
            visible: canDisplayEditActions,
            disabled: !deviceTagsLoaded || this.infosTabOptions().basicInfosTab.disabled,
            dispatchEvent: () => {
              this.analytic.log('tab_device_info_actions', 'open_manage_tags');
              return this.devicesCtaService.manageDeviceTags(device, tags);
            }
          },
          properties: deviceTagsLoaded
            ? tags?.length > 0
              ? tags?.map((tag) => ({
                  componentRef: {
                    ref: ChipComponent,
                    inputs: {
                      chip: tag,
                      chipButton: { display: false },
                      isTag: true
                    }
                  },
                  width: 'none'
                }))
              : [{ value: 'SITES.TABLE_CONTENT.EXPANDED_PANEL.NO_TAG', width: '100%' }]
            : undefined
        },
        {
          title: 'DEVICES.CARD.CONNECTION',
          collapsible: true,
          expanded: true,
          visibleExpression: () => this.isAdvancedSettingsTabSelected && device?.outgoingConnector?.requestConfiguration?.authentication === 'login',
          action: {
            visible: canDisplayEditActions,
            type: 'edit-device-connection-info' as OverviewCardActions,
            icon: 'edit',
            tooltip: 'IOT_DICTIONARY.EDIT',
            disabled: this.infosTabOptions().advancedSettingsTab.disabled,
            dispatchEvent: () => this.devicesCtaService.editDeviceConnectionInfo(device)
          },
          properties: [
            { label: 'DEVICES.CARD.LOGIN', value: device?.credential?.login, width },
            { label: 'DEVICES.CARD.PASSWORD', value: device?.credential?.password ? '•••••' : '-', width }
          ]
        }
      ]
    };
  }

  private hasCommand(command: CommandType): boolean {
    return !!this.device()?.outgoingConnector?.requestConfiguration?.commands?.includes(command);
  }
}
