import { AfterViewInit, Component, DestroyRef, effect, inject, Injector, input, output, Signal, signal, untracked, WritableSignal } from '@angular/core';
import { takeUntilDestroyed, toObservable, toSignal } from '@angular/core/rxjs-interop';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AbstractControl, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { FormControlHintType, FormControlType, FormField, FormGroupComponent, ImageSelectorModule } from '@iot-platform/iot-platform-ui';
import { NameValidators } from '@iot-platform/iot-platform-utils';
import { Asset, AssetOptionalProperty, AssetStatusName } from '@iot-platform/models/i4b';
import { AssetsService } from '@iot-platform/shared/services';
import { TranslateModule } from '@ngx-translate/core';
import { get, isEmpty, isEqual } from 'lodash';
import * as moment from 'moment';
import { combineLatest, finalize, map, Observable, of, switchMap } from 'rxjs';
import { AssetsDirectoryService } from '../../services/assets-directory.service';
import { AssetInfoFormControlName, AssetInfoFormGroup } from './asset-info-form.util';

const OPTIONAL_PROPERTIES: { label: string; value: string }[] = Object.values(AssetOptionalProperty).map((prop) => ({
  label: `ASSETS.MANAGE_PROPERTIES_FORM.${prop}`,
  value: prop
}));

@Component({
  imports: [
    TranslateModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    ImageSelectorModule,
    MatFormFieldModule,
    MatInputModule,
    MatOptionModule,
    MatDatepickerModule,
    MatIconModule,
    MatSelectModule,
    MatCheckboxModule,
    FormGroupComponent
  ],
  selector: 'iot4bos-ui-asset-info-form',
  templateUrl: './asset-info-form.component.html',
  styleUrls: ['./asset-info-form.component.scss']
})
export class AssetInfoFormComponent implements AfterViewInit {
  private readonly assetsService: AssetsService = inject(AssetsService);
  private readonly assetsDirectoryService: AssetsDirectoryService = inject(AssetsDirectoryService);
  private readonly destroyRef: DestroyRef = inject(DestroyRef);
  protected readonly injector: Injector = inject(Injector);

  asset = input<Asset>();
  optprops = input<Asset['optionalProperties']>();
  siteId = input<string>();
  canUpdateAsset = input<boolean>(false);
  canUpdateAssetContent = input<boolean>(false);
  selectedFields = input<AssetInfoFormControlName[]>([]);
  fieldsWidth = input<string>('50%');

  fillOutAsset = output<Asset | null>();

  form: AssetInfoFormGroup = new FormGroup({});
  initialFormState: unknown;
  fields: WritableSignal<Partial<FormField>[]> = signal([]);
  loading: WritableSignal<boolean> = signal<boolean>(true);
  // Lookups
  lookups: Signal<{
    quantities: string[];
    statuses: AssetStatusName[];
  }>;
  statuses: WritableSignal<AssetStatusName[]> = signal([]);
  quantities: WritableSignal<(string | null)[]> = signal([]);
  // Validators
  nameMaxLength = signal(50);
  descriptionMaxLength = signal(300);
  businessIdMaxLength = signal(30);
  shipToMaxLength = signal(50);
  // OptionalProperties
  isBusinessIdVisible = signal(false);
  isShipToVisible = signal(false);
  isDeliveryDateVisible = signal(false);
  isQuantity1Visible = signal(false);
  isQuantity2Visible = signal(false);

  selectedFields$ = toObservable(this.selectedFields);

  getFormControl(formControlName: AssetInfoFormControlName): AbstractControl {
    return this.form.get(formControlName) as AbstractControl;
  }

  onOptPropChange(event: MatCheckboxChange): void {
    let props: string[] = this.getFormControl(AssetInfoFormControlName.OPTIONAL_PROPERTIES)?.getRawValue() ?? [];
    if (event.checked) {
      props.push(event.source.value);
    } else {
      props = [...props.filter((p) => p !== event.source.value)];
    }
    this.getFormControl(AssetInfoFormControlName.OPTIONAL_PROPERTIES).setValue(props);
    this.updateOptionalPropertiesFieldsVisibility();
  }

  updateOptionalPropertiesFieldsVisibility(): void {
    this.isBusinessIdVisible.set(
      !!this.getFormControl(AssetInfoFormControlName.OPTIONAL_PROPERTIES)?.getRawValue()?.includes(AssetInfoFormControlName.BUSINESS_ID)
    );
    this.isShipToVisible.set(!!this.getFormControl(AssetInfoFormControlName.OPTIONAL_PROPERTIES)?.getRawValue()?.includes(AssetInfoFormControlName.SHIP_TO));
    this.isDeliveryDateVisible.set(
      !!this.getFormControl(AssetInfoFormControlName.OPTIONAL_PROPERTIES)?.getRawValue()?.includes(AssetInfoFormControlName.DELIVERY_DATE)
    );
    this.isQuantity1Visible.set(
      !!this.getFormControl(AssetInfoFormControlName.OPTIONAL_PROPERTIES)?.getRawValue()?.includes(AssetInfoFormControlName.QUANTITY_1)
    );
    this.isQuantity2Visible.set(
      !!this.getFormControl(AssetInfoFormControlName.OPTIONAL_PROPERTIES)?.getRawValue()?.includes(AssetInfoFormControlName.QUANTITY_2)
    );
  }

  // tata = effect(() => {
  //   const canUpdateAssetContent = this.canUpdateAssetContent();
  //   const canUpdateAsset = this.canUpdateAsset();
  //   const asset = this.asset();
  //
  //   if (canUpdateAssetContent && !canUpdateAsset) {
  //     this.form.disable();
  //     if (asset?.optionalProperties?.deliveryDate) {
  //       this.getFormControl(AssetInfoFormControlName.DELIVERY_DATE)?.enable();
  //     }
  //   }
  // });

  constructor() {
    this.lookups = toSignal(
      this.selectedFields$.pipe(
        switchMap(() => this.getLookups()),
        takeUntilDestroyed(this.destroyRef)
      )
    );
    this.initLookupsEffect();
    this.initializeFields();
  }

  ngAfterViewInit() {
    this.formChangesListener();
    this.initOptpropsEffect();
    this.initializeFormEffect();
    this.updateOptionalPropertiesFieldsVisibility();
  }

  get status() {
    const value: AssetStatusName = get(this.asset(), ['status', 'name']);
    return (
      this.statuses().find((s: AssetStatusName) => `${value}`?.trim().toLowerCase() === `${s}`.toString().trim().toLowerCase()) ?? AssetStatusName.production
    );
  }

  get quantity1() {
    const value: string | null = get(this.asset(), 'quantity1', null);
    return value ? this.quantities().find((q) => q === value) ?? null : null;
  }

  get quantity2() {
    const value: string | null = get(this.asset(), 'quantity2', null);
    return value ? this.quantities().find((q) => q === value) ?? null : null;
  }

  private formChangesListener(): void {
    this.form.events.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((events) => {
      if (!this.form.dirty) {
        this.initialFormState = this.form.getRawValue();
      }
      const formWithSortedProperties = { ...this.form.getRawValue() };
      (formWithSortedProperties.optionalProperties ?? []).sort();
      if (this.form.valid && !isEqual(this.initialFormState, formWithSortedProperties)) {
        this.fillOutAsset.emit(this.getAsset());
      } else {
        this.fillOutAsset.emit(null);
      }
    });
  }

  private getAsset(): Asset {
    const updatedAsset: Partial<Asset> = {
      expandedVariables: {},
      expandedTagCategories: {},
      tags: this.asset()?.tags ?? []
    } as unknown as Partial<Asset>;

    if (this.canUpdateAsset()) {
      if (this.selectedFields().includes(AssetInfoFormControlName.NAME)) {
        updatedAsset.name = this.getFormControl(AssetInfoFormControlName.NAME).getRawValue().trim();
      }
      if (this.selectedFields().includes(AssetInfoFormControlName.DESCRIPTION)) {
        updatedAsset.description = this.getFormControl(AssetInfoFormControlName.DESCRIPTION)?.getRawValue();
      }
      if (this.selectedFields().includes(AssetInfoFormControlName.STATUS)) {
        updatedAsset.status = {
          ...this.asset()?.status,
          name: this.getFormControl(AssetInfoFormControlName.STATUS)?.getRawValue()
        };
      }

      updatedAsset.optionalProperties = this.getOptionalProperties();

      Object.entries(this.getOptionalProperties() || {}).forEach(([key, value]) => {
        if (this.selectedFields().includes(AssetInfoFormControlName.OPTIONAL_PROPERTIES) || this.selectedFields().includes(key as AssetInfoFormControlName)) {
          if (!!updatedAsset.optionalProperties[key]) {
            updatedAsset[key] =
              !value || isEmpty(this.getFormControl(key as AssetInfoFormControlName)?.getRawValue())
                ? null
                : this.getFormControl(key as AssetInfoFormControlName)?.getRawValue();
          } else {
            updatedAsset[key] = (this.asset() as Asset)?.[key] ?? null;
          }
        }

        if (key === AssetInfoFormControlName.SHIP_TO && updatedAsset[AssetInfoFormControlName.SHIP_TO] !== undefined) {
          updatedAsset.erpReference = { shipTo: updatedAsset[AssetInfoFormControlName.SHIP_TO] };
        }

        if (key === AssetInfoFormControlName.DELIVERY_DATE) {
          if (!!updatedAsset.optionalProperties[key]) {
            updatedAsset[key] =
              !value || isEmpty(this.getFormControl(key as AssetInfoFormControlName)?.getRawValue())
                ? null
                : moment(this.getFormControl(AssetInfoFormControlName.DELIVERY_DATE)?.getRawValue()).format('yyyy-MM-DD');
          } else {
            updatedAsset[key] = (this.asset() as Asset)?.[key] ?? null;
          }
        }
      });
    } else if (this.canUpdateAssetContent() && this.getOptionalProperties()?.[AssetInfoFormControlName.DELIVERY_DATE]) {
      updatedAsset.deliveryDate = isEmpty(this.getFormControl(AssetInfoFormControlName.DELIVERY_DATE)?.getRawValue())
        ? null
        : moment(this.getFormControl(AssetInfoFormControlName.DELIVERY_DATE)?.getRawValue()).format('yyyy-MM-DD');
    }
    return updatedAsset as Asset;
  }

  getOptionalProperties(): { [key in AssetOptionalProperty]: boolean } | undefined {
    return Object.keys(AssetOptionalProperty).reduce((acc, prop) => {
      acc = {
        ...acc,
        [prop]: this.getFormControl(AssetInfoFormControlName.OPTIONAL_PROPERTIES)?.getRawValue().includes(prop)
      };
      return acc;
    }, {}) as { [key in AssetOptionalProperty]: boolean };
  }

  private getLookups(): Observable<{
    statuses: AssetStatusName[];
    quantities: string[];
  }> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const lookupList: Observable<any>[] = [];
    const selectedFields = this.selectedFields();
    if (selectedFields.includes(AssetInfoFormControlName.STATUS)) {
      lookupList.push(this.assetsDirectoryService.getAvailableStatusesForEdition());
    } else {
      lookupList.push(of([]));
    }
    if (
      selectedFields.includes(AssetInfoFormControlName.OPTIONAL_PROPERTIES) ||
      selectedFields.includes(AssetInfoFormControlName.QUANTITY_1) ||
      selectedFields.includes(AssetInfoFormControlName.QUANTITY_2)
    ) {
      lookupList.push(this.assetsDirectoryService.getQuantities());
    } else {
      lookupList.push(of([]));
    }

    return combineLatest(lookupList).pipe(
      map(([statuses, quantities]) => ({ statuses, quantities })),
      finalize(() => this.loading.set(false))
    );
  }

  private initLookupsEffect() {
    effect(() => {
      const lookups = this.lookups();
      if (lookups) {
        this.statuses.set(lookups.statuses);
        this.getFormControl(AssetInfoFormControlName.STATUS)?.setValue(this.status);
        this.quantities.set(lookups.quantities);
        this.getFormControl(AssetInfoFormControlName.QUANTITY_1)?.setValue(this.quantity1);
        this.getFormControl(AssetInfoFormControlName.QUANTITY_2)?.setValue(this.quantity2);
      }
    });
  }

  private initializeFields(): void {
    this.fields.set([
      {
        type: FormControlType.TEXT,
        name: signal(AssetInfoFormControlName.NAME),
        label: signal('ASSETS.INFO_FORM.ASSET_NAME'),
        maxLength: this.nameMaxLength,
        hint: signal({ type: signal(FormControlHintType.MAX_LENGTH) }),
        required: signal(true),
        fxFlex: this.fieldsWidth
      },
      {
        type: FormControlType.DROP_DOWN_LIST,
        name: signal(AssetInfoFormControlName.STATUS),
        label: signal('ASSETS.INFO_FORM.STATUS'),
        required: signal(true),
        fxFlex: this.fieldsWidth,
        items: this.statuses,
        trackBy: (item) => item,
        displayBy: (item) => 'ASSETS.CARD.STATUS_LIST.' + item
      },
      {
        type: FormControlType.TEXT,
        name: signal(AssetInfoFormControlName.BUSINESS_ID),
        label: signal('ASSETS.INFO_FORM.ID'),
        maxLength: this.businessIdMaxLength,
        hint: signal({ type: signal(FormControlHintType.MAX_LENGTH) }),
        fxFlex: this.fieldsWidth,
        visible: this.isBusinessIdVisible
      },
      {
        type: FormControlType.TEXT,
        name: signal(AssetInfoFormControlName.SHIP_TO),
        label: signal('ASSETS.INFO_FORM.SHIP_TO'),
        maxLength: this.shipToMaxLength,
        hint: signal({ type: signal(FormControlHintType.MAX_LENGTH) }),
        fxFlex: this.fieldsWidth,
        visible: this.isShipToVisible
      },
      {
        type: FormControlType.DATE_PICKER,
        name: signal(AssetInfoFormControlName.DELIVERY_DATE),
        label: signal('ASSETS.INFO_FORM.DELIVERY_DATE'),
        fxFlex: this.fieldsWidth,
        visible: this.isDeliveryDateVisible
      },
      {
        type: FormControlType.DROP_DOWN_LIST,
        name: signal(AssetInfoFormControlName.QUANTITY_1),
        label: signal('ASSETS.TEMPLATE_FORM.QUANTITY_1'),
        fxFlex: this.fieldsWidth,
        items: this.quantities,
        trackBy: (item) => item,
        displayBy: (item) => item,
        visible: this.isQuantity1Visible
      },
      {
        type: FormControlType.DROP_DOWN_LIST,
        name: signal(AssetInfoFormControlName.QUANTITY_2),
        label: signal('ASSETS.TEMPLATE_FORM.QUANTITY_2'),
        fxFlex: this.fieldsWidth,
        items: this.quantities,
        trackBy: (item) => item,
        displayBy: (item) => item,
        visible: this.isQuantity2Visible
      },
      {
        type: FormControlType.TEXT_AREA,
        name: signal(AssetInfoFormControlName.DESCRIPTION),
        label: signal('ASSETS.INFO_FORM.DESCRIPTION'),
        maxLength: this.descriptionMaxLength,
        hint: signal({ type: signal(FormControlHintType.MAX_LENGTH) }),
        required: signal(false),
        fxFlex: signal('100%')
      }
    ]);
  }

  static flattenOptionalProperties(options: Asset['optionalProperties']): string[] {
    return Object.entries(options)
      .filter(([key, value]) => !!value)
      .map(([key, value]) => key)
      .sort();
  }

  private initializeFormEffect(): void {
    effect(
      () => {
        const selectedFields = this.selectedFields();
        let asset: Asset | undefined;
        let optprops;
        untracked(() => {
          asset = this.asset();
          optprops = this.optprops();
        });

        this.form.addControl(
          AssetInfoFormControlName.OPTIONAL_PROPERTIES,
          new FormControl<string[]>(AssetInfoFormComponent.flattenOptionalProperties((asset?.optionalProperties ?? optprops) as Asset['optionalProperties']))
        );

        if (!this.canUpdateAsset() && this.canUpdateAssetContent() && asset?.optionalProperties?.deliveryDate) {
          this.form.addControl(
            AssetInfoFormControlName.DELIVERY_DATE,
            new FormControl<string | null>(get(asset, AssetInfoFormControlName.DELIVERY_DATE, null))
          );
        } else {
          if (selectedFields.includes(AssetInfoFormControlName.NAME)) {
            this.form.addControl(
              AssetInfoFormControlName.NAME,
              new FormControl<string | null>(get(asset, AssetInfoFormControlName.NAME, null), {
                validators: [Validators.minLength(1), Validators.maxLength(this.nameMaxLength()), Validators.required, Validators.pattern('\\S.*')],
                asyncValidators: [NameValidators.asyncUniqueNameValidator(this.assetsService, asset?.name ?? '', this.siteId())]
              })
            );
          }
          if (selectedFields.includes(AssetInfoFormControlName.STATUS)) {
            this.form.addControl(AssetInfoFormControlName.STATUS, new FormControl<AssetStatusName>(this.status, { validators: [Validators.required] }));
          }

          if (selectedFields.includes(AssetInfoFormControlName.BUSINESS_ID) || selectedFields.includes(AssetInfoFormControlName.OPTIONAL_PROPERTIES)) {
            this.form.addControl(
              AssetInfoFormControlName.BUSINESS_ID,
              new FormControl<string | null>(get(asset, AssetInfoFormControlName.BUSINESS_ID, null), {
                validators: [Validators.maxLength(this.businessIdMaxLength())]
              })
            );
          }
          if (selectedFields.includes(AssetInfoFormControlName.DELIVERY_DATE) || selectedFields.includes(AssetInfoFormControlName.OPTIONAL_PROPERTIES)) {
            this.form.addControl(
              AssetInfoFormControlName.DELIVERY_DATE,
              new FormControl<string | null>(get(asset, AssetInfoFormControlName.DELIVERY_DATE, null))
            );
          }
          // if (selectedFields.includes(AssetInfoFormControlName.PRODUCT_1)) {
          //   this.form.addControl(AssetInfoFormControlName.PRODUCT_1, new FormControl<Product>(get(asset, 'product1')));
          // }
          // if (selectedFields.includes(AssetInfoFormControlName.PRODUCT_2)) {
          //   this.form.addControl(AssetInfoFormControlName.PRODUCT_2, new FormControl<Product>(get(asset, 'product2')));
          // }
          if (selectedFields.includes(AssetInfoFormControlName.QUANTITY_1) || selectedFields.includes(AssetInfoFormControlName.OPTIONAL_PROPERTIES)) {
            this.form.addControl(AssetInfoFormControlName.QUANTITY_1, new FormControl<string | null>(this.quantity1));
          }
          if (selectedFields.includes(AssetInfoFormControlName.QUANTITY_2) || selectedFields.includes(AssetInfoFormControlName.OPTIONAL_PROPERTIES)) {
            this.form.addControl(AssetInfoFormControlName.QUANTITY_2, new FormControl<string | null>(this.quantity2));
          }
          if (selectedFields.includes(AssetInfoFormControlName.SHIP_TO) || selectedFields.includes(AssetInfoFormControlName.OPTIONAL_PROPERTIES)) {
            this.form.addControl(AssetInfoFormControlName.SHIP_TO, new FormControl<string | null>(get(asset, ['erpReference', 'shipTo'], null)));
          }
          if (selectedFields.includes(AssetInfoFormControlName.DESCRIPTION)) {
            this.form.addControl(
              AssetInfoFormControlName.DESCRIPTION,
              new FormControl<string | null>(get(asset, AssetInfoFormControlName.DESCRIPTION, null), {
                validators: [Validators.maxLength(this.descriptionMaxLength())]
              })
            );
          }
        }
        this.updateOptionalPropertiesFieldsVisibility();
      },
      { injector: this.injector }
    );
  }

  private initOptpropsEffect(): void {
    effect(
      () => {
        const optionalProperties = this.optprops();
        if (optionalProperties && !!this.getFormControl(AssetInfoFormControlName.OPTIONAL_PROPERTIES)) {
          Object.entries(optionalProperties).forEach(([key, value]) =>
            this.onOptPropChange({
              source: { value: key },
              checked: value
            } as MatCheckboxChange)
          );
        }
      },
      { injector: this.injector }
    );
  }

  protected readonly AssetInfoFormControlName = AssetInfoFormControlName;
  protected readonly OPTIONAL_PROPERTIES = OPTIONAL_PROPERTIES;
}
